import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/anzeige/0/0/0'
  },
  {
    path: '/obi/:kimono/:obijime',
    name: 'obi',
    component: () => import(/* webpackChunkName: "about" */ '../views/Obi.vue')
  },
  {
    path: '/kimono/:obi/:obijime',
    name: 'kimono',
    component: () => import(/* webpackChunkName: "about" */ '../views/Kimono.vue')
  },
  {
    path: '/obijime/:kimono/:obi',
    name: 'obijime',
    component: () => import(/* webpackChunkName: "about" */ '../views/Obijime.vue')
  },
  {
    path: '/anzeige/:kimono/:obi/:obijime',
    name: 'obi',
    component: () => import(/* webpackChunkName: "about" */ '../views/Anzeige.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "about" */ '../views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
